import {navigateToApp} from "~/components/navigate-to-top/navigate-to-top";

export default function TopNavBar(props: any) {
    return (
        <div className={"flex flex-row justify-between px-4 md:px-8 lg:px-24"}>
            <div>
                <a className={"font-bold text-xl"} href={"/"}>the bullish trade</a>
                        {/*<span onClick={() => navigateToApp("https://app.thebullish.trade", "app", "header-logo")}*/}
                        {/*      className={"font-bold text-xl"}>the bullish trade</span>*/}
            </div>
            <div className={"text-xl"}>
                <a className={"border-b"} href={"/downloads"}>downloads</a>
                {/*<span onClick={() => navigateToApp("/downloads", "downloads", "header-downloads")}*/}
                {/*      className={"cursor-pointer "}>downloads</span>*/}
                <span className={"mx-2"}> | </span>

                <span onClick={() => navigateToApp("https://app.thebullish.trade", "app", "header-signup")}
                      className={"cursor-pointer border-b"}>signup</span>
                <span className={"mx-2"}> | </span>

                <span onClick={() => navigateToApp("https://app.thebullish.trade", "app", "header-login")}
                      className={"cursor-pointer border-b"}>login</span>
            </div>
        </div>
    )
}