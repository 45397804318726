import mixpanel from 'mixpanel-browser';
// import {push} from "@socialgouv/matomo-next";
import posthog from 'posthog-js'

const navigateToApp = (url: string, whereTo: string, clickedOn: string) => {
    // push(["trackEvent", "Landing App Open", clickedOn]);
    mixpanel.track('Landing Click', {
        "Where to": whereTo,
        "Clicked on": clickedOn
    })

    posthog.capture('Landing Click', {
        "Where to": whereTo,
        "Clicked on": clickedOn,
    })
    // document.location.href = 'https://app.thebullish.trade/';
    let newTab = window.open(url, '_blank');
    if (newTab != null) {
        newTab.focus();
    }
}

export {navigateToApp}